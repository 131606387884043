.main-header {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    padding: 0 1rem;
    z-index: 5;
  }
  
  main {
    margin-top: 5rem;
  }
  
  @media (min-width: 768px) {
    .main-header {
      justify-content: space-between;
    }
  }

  .main-navigation__menu-btn {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
  }
  
  .main-navigation__menu-btn span {
    display: block;
    width: 2.5rem;
    height: 3px;
    background: #fcad0c;
    border-radius: 4px;
  }
  
  .main-navigation__title {
    color: black;
  }
  
  .main-navigation__title a {
    text-decoration: none;
    color: black;
  }

  .logo{
    height: 4rem;
    margin-top: 0.5rem;
  }
  
  .main-navigation__header-nav {
    display: none;
  }
  
  .main-navigation__drawer-nav {
    height: 100%;
  }
  
  @media (min-width: 768px) {
    .main-navigation__menu-btn {
      display: none;
    }
  
    .main-navigation__header-nav {
      display: block;
    }
  }