.body {
  margin-top: 5rem;
}

.banner {
  width: 100%;
}

.call-option {
  color: #fcad0c;
  display: inline-block;
}

.call-option a {
  text-decoration: none;
  color: #fcad0c;
  padding: 0.5rem;
  font-size: large;
  display: inline-block;
}

.mail-option {
  color: #fcad0c;
  display: inline-block;
}

.mail-option a {
  text-decoration: none;
  color: #fcad0c;
  padding: 0.5rem;
  font-size: large;
  display: inline-block;
}

.mail-call-container {
  float: right;
  vertical-align: top;
}

.top-section-text {
  color: #fcad0c;
  text-align: center;
  vertical-align: middle;
  margin-top: 2rem;
}

.top-section-text span {
  font-size: xx-large;
  font-weight: lighter;
  text-shadow: 1px 1px #aaaaaa;
}

.row {
  width: 100%;
  text-align: center;
  /* display: flex; */
  flex-wrap: wrap;
}

.wow {
  display: inline-block;
  min-width: 15rem;
  padding: 1rem;
  background-color: white;
  /* box-shadow: 1px 2px lightslategray; */
  margin: 0.5rem;
  /* border-radius: 1rem; */
}

.font-weight-regular {
  font-weight: normal;
  font-size: large;
}

.counter-modern-main {
  font-size: xx-large;
  font-weight: bold;
}

.react-icons {
  size: 2rem;
}

.section-counters {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.section-heading {
  text-align: center;
}

.about {
  margin: 2rem;
  display: flex;
  flex-flow: row wrap;
}

.specialities {
  display: inline-block;
  width: 40vw;
  min-width: fit-content;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
}

.specialities-aside {
  display: inline-block;
  min-width: 250px;
  max-width: 40vw;
  margin: 1rem;
  vertical-align: top;
  text-align: justify;
}

#services {
  background-color: whitesmoke;
  padding-top: 1rem;
}

.section-description {
  font-weight: lighter;
  font-size: larger;
  text-align: center;
  margin: 1rem;
  max-width: 90%;
}

.services-item {
  display: inline-block;
  min-width: 15rem;
  max-width: 15rem;
  margin: 1rem;
}

.box-classic-light {
  border: 1px solid lightgray;
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
}

.box-classic-light h2 {
  font-weight: lighter;
}

.box-classic-light p {
  font-size: larger;
  font-weight: lighter;
}

.horizontal-scroll {
  height: fit-content;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.fleet-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 90%;
  /* display: flex; */
  /* justify-content: center; */
  flex-wrap: wrap;
}

.fleet-item {
  display: inline-block;
  min-width: 15rem;
  max-width: 20%;
  margin: 1rem;
}

.fleet-item h2 {
  font-weight: lighter;
}

.fleet-item img {
  width: 15rem;
  height: 15rem;
  object-fit: contain;
}

#advantages {
  background-color: #333333;
  color: white;
  padding: 1rem;
}

.terms {
  background-color: whitesmoke;
  padding: 2rem;
}

#contact {
  text-align: center;
}

.address-container a {
  text-decoration: none;
  color: #fcad0c;
  font-size: large;
}

.address-container ul {
  list-style: none;
}

.contact-elements {
  display: inline-block;
  vertical-align: top;
  padding: 1rem;
}

.gmap {
  width: 100%;
}

.footer {
  color: white;
  width: 100%;
  background-color: darkslategray;
  text-align: center;
}

.filler {
  height: 5rem;
}
